import { useState } from 'preact/hooks'
import type { Component_Menucomponent_Menu_Links_Submenu } from '../../__generated__/types'
import PreactLink from '../../lib/preact/PreactLink'
import clsx from 'clsx'

export default function Submenu({
  submenu,
  active,
  requestClose,
}: {
  submenu: Component_Menucomponent_Menu_Links_Submenu
  active: boolean
  requestClose: Function
}) {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div
      class={clsx(
        active && 'animate-fade-up animate-duration-150',
        'absolute w-screen left-0 top-24 group-data-[sticky="1"]/nav:top-14 z-[99] transition-all'
      )}
    >
      <div class="container flex justify-end">
        <div class="relative w-2/3 pt-3" onMouseLeave={() => requestClose()}>
          <div class="grid grid-cols-2 gap-7 overflow-hidden bg-white shadow-md rounded-2xl opacity-0 translate-y-2 transition-transform group-hover/menuitem:opacity-100 group-hover/menuitem:translate-y-0">
            <div class="col-span-1 py-5 pl-5">
              {submenu.title && <span className="font-headings font-bold text-xl text-am-navy inline-block mb-2">{submenu.title}</span>}
              <ul>
                {submenu?.menu?.map((edge, index) => {
                  return (
                    <li data-index={index} class="submenu-item">
                      <PreactLink
                        // @ts-ignore
                        onMouseEnter={() => setCurrentIndex(index)}
                        className="text-am-navy  font-medium bg-white mb-2 lg:mb-0 hover:bg-gray-50 transition-colors p-3 rounded-lg w-full flex items-center justify-between"
                        href={edge?.link?.url || '#'}
                      >
                        <span dangerouslySetInnerHTML={{ __html: edge?.link?.title || '' }}></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                          <path
                            class="fill-am-navy"
                            d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"
                          />
                        </svg>
                      </PreactLink>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div class="col-span-1">
              <div className="relative h-full">
                <PreactLink href={submenu.menu![currentIndex]?.link?.url} className="group block h-full">
                  <img
                    key={`submenu-image-${currentIndex}`}
                    class="aspect-square object-cover group-hover:opacity-75 transition-opacity h-full"
                    src={submenu.menu![currentIndex]?.image?.mediaItemUrl || ''}
                    alt=""
                    width={1000}
                    height={1000}
                  />
                </PreactLink>
                <div className="pointer-events-none absolute top-0 left-0 h-full w-2/3 bg-gradient-to-r from-white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
