import Submenu from './Submenu'
import type { Component_Menucomponent } from '../../__generated__/types'
import PreactLink from '../../lib/preact/PreactLink'
import { useState } from 'preact/hooks'

export default function MenuLg({ fields }: { fields: Component_Menucomponent }) {
  const [currentMenuItemIndex, setCurrentMenuItemIndex] = useState(-1)

  return (
    <ul class="items-center justify-center hidden lg:flex">
      {fields.menu?.links?.map((edge, index) => {
        const active = currentMenuItemIndex === index

        return (
          <li class="mx-4">
            <div onMouseEnter={() => setCurrentMenuItemIndex(index)} class="group/menuitem">
              <div class="flex items-center">
                <PreactLink
                  className="font-headings text-white group-hover/menuitem:text-am-orange transition-colors px-2 py-2"
                  href={edge?.link?.url || ''}
                >
                  {edge?.link?.title}
                </PreactLink>
                {edge?.submenu?.menu && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 512 512"
                    class="-rotate-90 lg:rotate-0 lg:group-hover/menuitem:-rotate-180 transition-transform"
                  >
                    <path
                      fill="none"
                      stroke="#FFA75E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="m112 184l144 144l144-144"
                    />
                  </svg>
                )}
              </div>

              {edge?.submenu?.menu && active && (
                <Submenu submenu={edge.submenu} active={active} requestClose={() => setCurrentMenuItemIndex(-1)} />
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
